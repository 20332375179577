export const firebaseConfig = {
  apiKey: "AIzaSyC4qX6dvt-yxePiBzvK0zM59lMkchkaNPQ",
  authDomain: "ya-review.firebaseapp.com",
  databaseURL: "https://ya-review-default-rtdb.firebaseio.com",
  projectId: "ya-review",
  storageBucket: "ya-review.appspot.com",
  messagingSenderId: "557793189957",
  appId: "1:557793189957:web:76dac6bca5624155bd432c",
  measurementId: "G-E4JYCKEWEY",
};
