import { User } from "firebase/auth";

import { User as UserData } from "@yadada/interfaces/shared/types/user";
import { createContext } from "./utils";

export type UserAccessScope = "anonymous" | "auth" | "none";

export interface AuthContext {
  user: User | null;
  userData: UserData | null;
  isUserLoaded: boolean;
  userAccessScope: UserAccessScope;
  // setIsUserLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const [useAuthContext, AuthContextProvider, AuthContextConsumer] =
  createContext<AuthContext>();

export { useAuthContext, AuthContextProvider, AuthContextConsumer };
