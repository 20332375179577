import { createContext } from "./utils";

export interface Alert {
  type: "alert" | "info" | "success" | "warning";
  message: string | React.ReactNode;
  title?: string;
  isFixed?: boolean;
  isInline?: boolean;
  className?: string;
}

export interface MediaDeviceInfoPair {
  audio: MediaDeviceInfo | null;
  video: MediaDeviceInfo | null;
}

export interface AppContext {
  alert: Alert | null;
  activeMedia: MediaDeviceInfoPair;
  showMediaDeviceModal: boolean;
  userMediaRequested: boolean;
  audioRequested: boolean;
  dispatch: React.Dispatch<AppContextAction>;
}

export type AppContextAlertAction = {
  type: "triggerAlert" | "closeAlert";
  payload: Alert | null;
};

export type AppContextSetMediaAction = {
  type: "setActiveDevices";
  payload: MediaDeviceInfoPair;
};

export type AppContextUserMediaRequestedAction = {
  type: "userMediaRequested";
  payload: boolean;
};

export type AppContextRequestMediaDevicesAction = {
  type: "showActiveDevices";
  payload: boolean;
};

export type AppContextAction =
  | AppContextAlertAction
  | AppContextSetMediaAction
  | AppContextRequestMediaDevicesAction
  | AppContextUserMediaRequestedAction;

const [useAppContext, AppContextProvider, AppContextConsumer] =
  createContext<AppContext>();

export { useAppContext, AppContextProvider, AppContextConsumer };
