import { auth, googleProvider } from "./firebase.service";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence,
  signInAnonymously,
} from "firebase/auth";

// should be more part of the function itself, but given the trivial flow, for now leaving it on top
setPersistence(auth, browserLocalPersistence);

export class AuthService {
  public static async login(email: string, password: string) {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res;
  }

  public static async register(email: string, password: string) {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    return res;
  }

  public static async loginWithGoogle() {
    const res = await signInWithPopup(auth, googleProvider);
    return res;
  }

  public static async signInAnonymously() {
    const res = await signInAnonymously(auth);
    return res;
  }

  public static async logout() {
    await auth.signOut();
  }
}
