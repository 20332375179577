import React, { useEffect, useMemo, useReducer } from "react";
import { Link } from "react-router-dom";
import { Outlet, useMatch, useNavigate } from "react-router-dom";

import { useAuthContext } from "@yadada/contexts/auth";
import ROUTES from "@yadada/ui/pages/Router/routes";
import { ReactComponent as LogoutSVG } from "@yadada/ui/icons/logout.svg";

import {
  AppContext,
  AppContextAction,
  AppContextProvider,
} from "./contexts/app";
import Alert from "./ui/components/Alert";
import ErrorBoundary from "./ui/components/ErrorBoundary";
import { AuthService } from "./services/auth.service";
import { YadadaActiveDevicesKey } from "./types-constants";
import MediaDeviceListModal from "./ui/components/media/MediaDeviceListModal";

function appStateReducer(
  state: Omit<AppContext, "dispatch">,
  action: AppContextAction,
): Omit<AppContext, "dispatch"> {
  switch (action.type) {
    case "triggerAlert": {
      return { ...state, alert: action.payload };
    }
    case "closeAlert": {
      return { ...state, alert: null };
    }
    case "setActiveDevices": {
      return { ...state, activeMedia: action.payload };
    }
    case "showActiveDevices": {
      return { ...state, showMediaDeviceModal: action.payload };
    }
    case "userMediaRequested": {
      return {
        ...state,
        userMediaRequested: action.payload,
        audioRequested: action.payload,
      };
    }
    default: {
      throw new Error("Unsupported Action Type");
    }
  }
}

function getStoredMediaDevices() {
  return localStorage.getItem(YadadaActiveDevicesKey)
    ? JSON.parse(localStorage.getItem(YadadaActiveDevicesKey)!)
    : { audio: null, video: null };
}

const App: React.FC = () => {
  const [state, dispatch] = useReducer(appStateReducer, {
    alert: null,
    showMediaDeviceModal: false,
    userMediaRequested: !!(
      getStoredMediaDevices().audio && getStoredMediaDevices().video
    ),
    audioRequested: !!getStoredMediaDevices().audio,
    activeMedia: getStoredMediaDevices(),
  });

  const appContextValue = useMemo(() => ({ ...state, dispatch }), [state]);

  const currentUser = useAuthContext();
  const navigate = useNavigate();
  const match = useMatch("/");
  useEffect(() => {
    if (!!match) {
      navigate(ROUTES.dashboard);
    }
  }, [match, navigate]);

  return (
    <ErrorBoundary>
      <React.Suspense fallback={<div>Loading...</div>}>
        <AppContextProvider value={appContextValue}>
          <header>
            <nav className="border-b border-gray-200 px-2 py-2.5 sm:px-4">
              <div className="flex flex-wrap items-center justify-between">
                <Link to={ROUTES.dashboard}>
                  <h1
                    className="text-2xl font-bold text-yadadaBlue"
                    id="header-yadada-logo"
                  >
                    yadada
                  </h1>
                </Link>
                {currentUser.userData && (
                  <div className="flex items-center justify-end">
                    <Link to={ROUTES.dashboard}>
                      <img
                        className="rounded-full"
                        height={36}
                        width={36}
                        src={currentUser.userData.profilePhoto}
                        alt="profile"
                        id="header-user-photo"
                      />
                    </Link>
                    <button
                      className="ml-4 h-6 w-6 hover:text-yadadaBlue"
                      onClick={() => {
                        AuthService.logout();
                      }}
                    >
                      <LogoutSVG />
                    </button>
                  </div>
                )}
              </div>
            </nav>
          </header>
          <main className="h-full min-h-screen w-full bg-gray-50">
            <Outlet />
          </main>
          {state.alert && (
            <Alert
              title={state.alert.title}
              type={state.alert.type}
              onDismiss={() => dispatch({ type: "closeAlert", payload: null })}
            >
              {state.alert.message}
            </Alert>
          )}
          <MediaDeviceListModal />
        </AppContextProvider>
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default App;
