import React from "react";
import clsx from "clsx";
import {
  CustomSizeCompatible,
  CustomStateCompatible,
  IconCompatible,
} from "../types";
import Loading from "../Loading";

interface ButtonProps
  extends IconCompatible,
    CustomSizeCompatible,
    CustomStateCompatible {
  // TODO this should be taken from TS definitions, but I hit problem -> https://github.com/DefinitelyTyped/DefinitelyTyped/blob/1c0d34eff93e70c289fb4207174e8bfd0f37b0d4/types/tailwindcss/colors.d.ts
  color: "indigo" | "red" | "gray" | "light-gray" | "empty";
}

const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps &
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
>(
  (
    {
      disabled,
      isLoading,
      children,
      color = "indigo",
      className,
      size = "md",
      icon,
      active,
      ...rest
    },
    ref,
  ) => {
    // This is needed due to JIT -> see semantics https://v2.tailwindcss.com/docs/just-in-time-mode

    const _color = clsx(
      color === "indigo" && [
        "text-white bg-indigo-600 hover:bg-indigo-800 active:ring-indigo-300",
        (disabled || isLoading) && "bg-indigo-300 hover:bg-indigo-300",
        active && "bg-indigo-800",
      ],
      color === "red" && [
        "text-white bg-red-500 hover:bg-red-700 active:ring-red-300",
        (disabled || isLoading) && "bg-red-300 hover:bg-red-300",
        active && "bg-red-700",
      ],
      color === "gray" && [
        "text-gray-900 bg-gray-200 hover:bg-gray-500 active:ring-gray-300 hover:text-white",
        (disabled || isLoading) && "bg-gray-300 hover:bg-gray-300",
        active && "bg-gray-800 !text-white",
      ],
      color === "light-gray" && [
        "text-gray-500 bg-gray-50 hover:bg-gray-800 active:bg-gray-800 active:ring-gray-300 hover:text-white",
        (disabled || isLoading) && "bg-gray-300 hover:bg-gray-300",
        active && "bg-gray-800 !text-white",
      ],
      color === "empty" && [
        "text-gray-600 active:ring-blue-300 hover:bg-yadadaBlue hover:text-white",
        (disabled || isLoading) && "bg-gray-200 hover:bg-gray-200",
        active && "font-semibold bg-yadadaBlue !text-white",
      ],
    );

    const _sizeCSS = clsx({
      "font-medium rounded-lg text-base px-6 py-3.5": size === "lg",
      "font-medium rounded-lg text-sm px-5 py-2.5": size === "md",
      "px-2.5 py-1.5 rounded font-normal hover:font-semibold": size === "sm",
      "px-1.5 py-1 rounded font-normal hover:font-semibold text-xs":
        size === "xs",
    });

    let render = children;

    if (icon) {
      if (icon.position === "left") {
        render = (
          <>
            {icon.node}
            <div className={clsx("pl-1", icon.customClassName)}>{children}</div>
          </>
        );
      } else if (icon.position === "right") {
        render = (
          <>
            <div className={clsx("pr-1", icon.customClassName)}>{children}</div>
            {icon.node}
          </>
        );
      } else {
        render = <div className={icon.customClassName}>{icon.node}</div>;
      }
    }

    return (
      <button
        className={clsx(
          "flex items-center font-body active:ring-4",
          ` ${_color} ${_sizeCSS}`,
          (disabled || isLoading) && `cursor-not-allowed active:ring-0`,
          icon?.position === "center" && "justify-center",
          className,
        )}
        type="button"
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {!isLoading ? (
          render
        ) : (
          <>
            <Loading />
            {children}
          </>
        )}
      </button>
    );
  },
);

export default Button;
