import { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import clsx from "clsx";

import { useAppContext } from "@yadada/contexts/app";
import { YadadaActiveDevicesKey } from "@yadada/types-constants";
import Button from "../Buttons/Button";

const MediaDeviceListModal: React.FC = () => {
  const {
    dispatch: appCtxDispatch,
    showMediaDeviceModal,
    activeMedia,
  } = useAppContext();
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [isInSelectionProcess, setIsInSelectionProcess] =
    useState<boolean>(false);

  useEffect(() => {
    if (showMediaDeviceModal && !isInSelectionProcess) {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        setIsInSelectionProcess(true);
        console.log(devices);
        const videoDevices = devices.filter(
          device => device.kind === "videoinput" && device.deviceId !== "",
        );
        const audioDevices = devices.filter(
          device => device.kind === "audioinput" && device.deviceId !== "",
        );
        setVideoDevices(videoDevices);
        setAudioDevices(audioDevices);
        let preferredVideo = activeMedia
          ? videoDevices.find(
              device => device.deviceId === activeMedia.video?.deviceId,
            ) || videoDevices[0]
          : videoDevices[0];
        let preferredAudio = activeMedia
          ? audioDevices.find(
              device => device.deviceId === activeMedia.audio?.deviceId,
            ) || audioDevices[0]
          : audioDevices[0];
        if (videoDevices.length > 1 && !activeMedia.video) {
          preferredVideo =
            videoDevices.find(device => device.deviceId === "default") ||
            preferredVideo;
        }
        if (audioDevices.length > 1 && !activeMedia.audio) {
          preferredAudio =
            audioDevices.find(device => device.deviceId === "default") ||
            preferredAudio;
        }

        const devicePairInfo = {
          audio: preferredAudio || null,
          video: preferredVideo || null,
        };
        appCtxDispatch({ type: "setActiveDevices", payload: devicePairInfo });

        if (!preferredAudio && !preferredVideo) {
          alert(
            `Yadada App: we can't detect any ${
              !preferredVideo ? "video" : ""
            } ${
              !preferredAudio ? (!preferredVideo ? "and audio" : "audio") : ""
            } device.\nPlease make sure to connect your hardware before using Yadada studio`,
          );
        }
      });
    }
  }, [activeMedia, appCtxDispatch, isInSelectionProcess, showMediaDeviceModal]);

  const handleOnClose = useCallback(() => {
    localStorage.setItem(YadadaActiveDevicesKey, JSON.stringify(activeMedia));
    appCtxDispatch({ type: "userMediaRequested", payload: true });
    appCtxDispatch({ type: "showActiveDevices", payload: false });
    setIsInSelectionProcess(false);
  }, [appCtxDispatch, activeMedia]);

  return (
    <ReactModal
      isOpen={showMediaDeviceModal}
      contentLabel="List of Media Devices"
      onRequestClose={handleOnClose}
      className="max-w-4xl"
      appElement={document.getElementById("root") || undefined}
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "RGBA(209, 213, 219, 0.7)",
          zIndex: 999,
        },
      }}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <main className="grid h-max w-full gap-8 rounded-md bg-white p-4 font-normal text-black">
        <h1 className="text-lg font-semibold">
          Select your camera and microphone
        </h1>
        <section>
          <h2 className="mb-2 rounded p-1 text-base font-medium">Webcams</h2>
          {videoDevices.length > 0 && (
            <ul className="grid gap-2">
              {videoDevices.map(device => (
                <li
                  key={device.deviceId}
                  aria-current={device === activeMedia.video}
                  className={clsx(
                    device === activeMedia.video &&
                      "bg-blue-700 text-white hover:bg-blue-700 hover:!text-white",
                    "rounded-md",
                    "hover:bg-gray-100 hover:text-blue-700 focus:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700",
                  )}
                >
                  <button
                    className="w-full"
                    disabled={device === activeMedia.video}
                    onClick={() =>
                      appCtxDispatch({
                        type: "setActiveDevices",
                        payload: {
                          ...activeMedia,
                          video: device,
                        },
                      })
                    }
                  >
                    {device.label}
                  </button>
                </li>
              ))}
            </ul>
          )}
          {videoDevices.length === 0 && (
            <h3 className="text-sm font-normal">
              No video devices were requested/found
            </h3>
          )}
        </section>
        <section>
          <h2 className="mb-2 rounded  p-1 text-base font-medium">
            Microphones
          </h2>
          <ul className="grid gap-2">
            {audioDevices.map(device => (
              <li
                key={device.deviceId}
                aria-current={device === activeMedia.audio}
                className={clsx(
                  device === activeMedia.audio &&
                    "bg-blue-700 text-white hover:bg-blue-700 hover:!text-white",
                  "rounded-md",
                  "hover:bg-gray-100 hover:text-blue-700 focus:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700",
                )}
              >
                <button
                  className="w-full"
                  disabled={device === activeMedia.audio}
                  onClick={() =>
                    appCtxDispatch({
                      type: "setActiveDevices",
                      payload: {
                        ...activeMedia,
                        audio: device,
                      },
                    })
                  }
                >
                  {device.label}
                </button>
              </li>
            ))}
          </ul>
        </section>
        <footer className="flex justify-center">
          <Button color="indigo" onClick={handleOnClose}>
            Confirm
          </Button>
        </footer>
      </main>
    </ReactModal>
  );
};

export default MediaDeviceListModal;
