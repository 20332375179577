import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";

import { User, UserRole } from "@yadada/interfaces/shared/types/user";

import { firestore, functions } from "./firebase.service";

export class UserService {
  public static async pairEducator(educatorId: string) {
    await httpsCallable(
      functions,
      "app-user-pair",
    )({
      educatorId,
    });
  }
  public static async updateUserRole(role: UserRole, educatorId?: string) {
    httpsCallable(
      functions,
      "app-user-role_update",
    )({
      role,
      educatorId,
    });
  }
  public static async getUserData(uid: string): Promise<User | null> {
    const snap = await getDoc(doc(firestore, `users/${uid}`));
    if (snap.exists()) {
      return snap.data() as User;
    }
    return null;
  }
}
