import React from "react";
import Toast from "./Alert";

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(e: any) {
    // TODO
    // sentry report
  }

  render() {
    if (this.state.hasError) {
      <>{this.props.children}</>;
      <Toast
        title="Unknown Error :("
        type="alert"
        onDismiss={() => this.setState({ hasError: false })}
      >
        We are deeply sorry, we will make it better next time!
      </Toast>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
