const ROUTES = {
  dashboard: "/dashboard",
  login: "/login",
  editChallenge: (id: string = ":id") => `/edit-challenge/${id}`,
  feedbackRoot: "/feedback/:id",
  feedbackOverview: (id: string = ":id") => `/feedback/${id}/overview`,
  feedbackChallenge: (id: string = ":id") => `/feedback/${id}/challenge`,
  challengeInviteRoot: "/challenge-invite/:id",
  challengeInviteStepOne: (id: string = ":id") =>
    `/challenge-invite/${id}/confirm`,
  challengeInviteStepTwo: (id: string = ":id") =>
    `/challenge-invite/${id}/answer`,
  challengeInviteStepThree: (id: string = ":id") =>
    `/challenge-invite/${id}/lobby`,
  challengeInviteFeedback: (id: string = ":id") =>
    `/challenge-invite/${id}/feedback`,
  challengeInviteOverview: (id: string = ":id") =>
    `/challenge-invite/${id}/overview`,
  challengeSummary: "/create-challenge/summary",
  createChallenge: "/create-challenge",
};

export default ROUTES;
