import React from "react";

function createContext<ContextType>(ctx: ContextType | undefined = undefined) {
  const context = React.createContext<ContextType>(ctx as ContextType);
  function useContext() {
    const ctx = React.useContext(context) as ContextType;
    if (!ctx) {
      throw new Error("useContext must be inside a Provider with a value");
    }
    return ctx;
  }
  return [useContext, context.Provider, context.Consumer] as const;
}

export { createContext };
