import React, { useEffect, useMemo, useState } from "react";
import { User } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";

import { User as UserData } from "@yadada/interfaces/shared/types/user";

import { auth, firestore } from "../services/firebase.service";
import {
  AuthContextProvider,
  AuthContext,
  UserAccessScope,
} from "../contexts/auth";
import { UserService } from "../services/user.service";

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false);
  const userAccessScope: UserAccessScope = useMemo(() => {
    if (user && !userData) {
      return "anonymous";
    }
    if (!user || !userData) {
      return "none";
    }
    return "auth";
  }, [user, userData]);

  const contextValue: AuthContext = {
    user,
    userData,
    isUserLoaded,
    userAccessScope,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (firebaseUser: User | null) => {
        // console.log("firebaseUser", firebaseUser);
        if (firebaseUser) {
          if (firebaseUser.isAnonymous) {
            setUser(firebaseUser);
          } else if (firebaseUser.email !== user?.email) {
            setUser(firebaseUser);
            const uid = firebaseUser?.uid;
            if (uid != null) {
              const userData = await UserService.getUserData(uid);
              if (!userData) {
                const q = query(
                  collection(firestore, `users`),
                  where("userId", "==", uid),
                );
                const snapshotUnsubscribe = onSnapshot(q, querySnapshot => {
                  const userData = querySnapshot.docs[0].data() as UserData;
                  setUserData(userData);
                  snapshotUnsubscribe();
                });
              }
              setUserData(userData);
            }
          }
          setIsUserLoaded(true);
        } else {
          setIsUserLoaded(true);
          setUser(null);
          setUserData(null);
        }
      },
      error => {
        console.error("Yadada::AuthError::", error);
      },
      () => {
        console.log("Yadada::auth::completed");
        setIsUserLoaded(true);
      },
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContextProvider value={contextValue}>{children}</AuthContextProvider>
  );
};
