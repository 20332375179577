import clsx from "clsx";

import { Alert as AlertContextProps } from "@yadada/contexts/app";
import { ReactComponent as CheckCircleSVG } from "@yadada/ui/icons/check-circle.svg";

interface AlertProps extends Omit<AlertContextProps, "message"> {
  onDismiss: () => void;
}

const Alert: React.FC<AlertProps> = ({
  type,
  title,
  children,
  onDismiss,
  isFixed = true,
  isInline = false,
}) => {
  const isIconShowing = type === "success"; // for now ad-hoc impl
  return (
    <button onClick={onDismiss} className="relative w-full">
      <div
        className={clsx(
          isFixed && "fixed inset-x-0 bottom-10 z-50 mb-4",
          "rounded-lg p-4 text-sm hover:cursor-pointer",
          {
            "bg-blue-100 text-blue-700 dark:bg-blue-200 dark:text-blue-800":
              type === "info",
            "bg-red-100 text-red-700 dark:bg-red-200 dark:text-red-800":
              type === "alert",
            "bg-green-100 text-green-700 dark:bg-green-200 dark:text-green-800":
              type === "success",
            "bg-yellow-100 text-yellow-700 dark:bg-yellow-200 dark:text-yellow-800":
              type === "warning",
          },
        )}
        role="alert"
      >
        <div className="font-bold">{title}</div>
        <div
          className={clsx("grid", {
            "grid-cols-[auto_1fr]": !isInline && isIconShowing,
            "grid-cols-[auto_auto]": isInline && !isIconShowing,
            "grid-cols-[auto_1fr_auto]": isIconShowing && isInline,
          })}
        >
          {isIconShowing && (
            <div className="pr-3">
              {type === "success" && <CheckCircleSVG />}
            </div>
          )}
          <div className={clsx(isIconShowing && "justify-self-start")}>
            {children}
          </div>
          <div
            className={clsx(
              "font-green-700 font-bold",
              !isInline && "absolute top-2 right-3",
            )}
          >
            ×
          </div>
        </div>
      </div>
    </button>
  );
};

export default Alert;
