import React from "react";
import { Routes, Route } from "react-router-dom";

import App from "@yadada/App";
import ROUTES from "./routes";
import { ChallengePageProps } from "../Challenge/types";

const LoginLazy = React.lazy(() => import("@yadada/ui/pages/Login"));
const ChallengeLazy = React.lazy(
  () => import("@yadada/ui/pages/Challenge"),
) as React.FC<ChallengePageProps>;
const DashboardLazy = React.lazy(() => import("@yadada/ui/pages/Dashboard"));
const FeedbackLazy = React.lazy(
  () => import("@yadada/ui/pages/Feedback/views/Teacher"),
);
const ChallengeSummaryLazy = React.lazy(
  () => import("@yadada/ui/pages/Challenge/Link"),
);

const StudentAnswerStepOne = React.lazy(
  () => import("@yadada/ui/pages/StudentAnswer/Step1"),
);

const StudentAnswerStepTwo = React.lazy(
  () => import("@yadada/ui/pages/StudentAnswer/Step2"),
);

const StudentAnswerStepThree = React.lazy(
  () => import("@yadada/ui/pages/StudentAnswer/Step3"),
);

const StudentAnswerFeedback = React.lazy(
  () => import("@yadada/ui/pages/Feedback/views/Student"),
);

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={ROUTES.login} element={<LoginLazy />} />
        <Route
          path={ROUTES.challengeSummary}
          element={<ChallengeSummaryLazy />}
        />
        <Route
          path={ROUTES.createChallenge}
          element={<ChallengeLazy type="create" />}
        />
        <Route
          path={ROUTES.editChallenge()}
          element={<ChallengeLazy type="edit" />}
        />
        <Route
          path={ROUTES.challengeInviteStepOne()}
          element={<StudentAnswerStepOne />}
        />
        <Route
          path={ROUTES.challengeInviteStepTwo()}
          element={<StudentAnswerStepTwo />}
        />
        <Route
          path={ROUTES.challengeInviteStepThree()}
          element={<StudentAnswerStepThree />}
        />
        <Route
          path={`${ROUTES.challengeInviteRoot}/*`}
          element={<StudentAnswerFeedback />}
        />
        <Route path={ROUTES.dashboard} element={<DashboardLazy />} />
        <Route path={`${ROUTES.feedbackRoot}/*`} element={<FeedbackLazy />} />
      </Route>
    </Routes>
  );
};

export default Router;
